import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      users: null,
    },
    mutations: {
      updateUsers(state, users) {
        state.users = users;
      },
      clearUsers(state) {
        state.users = null;
      },
    },
    actions: {
      refreshUsers({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/user',
        }).then(
          (result) => {
            commit('updateUsers', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      deleteUser({ dispatch }, userId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'DELETE',
          url: `/api/v1/user/${userId}`,
        }).then(
          () => {
            dispatch('refreshUsers');
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      // eslint-disable-next-line no-unused-vars
      updateUserState({ dispatch, commit }, { userId, userState }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: userState ? 'PUT' : 'DELETE',
          url: `/api/v1/user_state/${userId}`,
        }).then(
          () => {
            dispatch('refreshUsers');
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      // eslint-disable-next-line no-unused-vars
      addUser({ dispatch, commit }, params) {
        return new Promise((resolve, reject) => {
          this.state.auth.axios_instance({
            method: 'PUT',
            url: '/api/v1/user',
            data: params,
          }).then(
            // eslint-disable-next-line no-unused-vars
            (result) => {
              dispatch('refreshUsers');
              resolve();
            },
            (error) => {
              promiseErrorHandler(error, reject);
            },
          );
        });
      },
    },
  }
);
