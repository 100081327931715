<template>
<v-navigation-drawer
  :value="value"
  @input="$emit('input', $event)"
  color="#1F3870"
  absolute
  temporary
  class="mobile-sidebar-container"
  ref="navigation_drawer">
  <div class="close-icon-container">
    <MaterialIcon
      class="toggle-mobile-sidebar-icon"
      type="close"
      isClickable
      color="#8DABF2"
      ref="material_icon_action"
      @click="$emit('input', false)"
    />
  </div>
  <ul class="mobile-links-container">
    <SidebarPageLink
      v-if="compSearchEnabled"
      id="component_browser_tab"
      route="/component-browser"
      icon="/images/component-browser-unselected.svg"
      light-icon="/images/component-browser-selected.svg"
      title="Component Browser"
      :child-route-names="[
        'componentBrowser',
        'componentResults',
        'componentDisplay',
      ]"
      ref="component_browser_page_route"
    />
<!--    <SidebarPageLink-->
<!--      :indicate-premium="!scansEnabled"-->
<!--      id="scans_tab"-->
<!--      route="/boms"-->
<!--      icon="fas fa-microchip fa-icon"-->
<!--      title="Scans"-->
<!--      :child-route-names="[-->
<!--        'upgrade-to-boms',-->
<!--      ]"-->
<!--      ref="boms_page_route"-->
<!--    />-->
<!--    <SidebarPageLink-->
<!--      :indicate-premium="!huntsEnabled"-->
<!--      id="hunter_tab"-->
<!--      route="/hunter"-->
<!--      icon="fas fa-crosshairs fa-icon"-->
<!--      title="Hunter"-->
<!--      :child-route-names="[-->
<!--        'upgrade-to-hunter',-->
<!--      ]"-->
<!--      ref="hunts_page_route"-->
<!--    />-->
  </ul>
  <li class="logout-action-link">
    <a
      class="logout-action"
      @click="logoutUser()"
      ref="logout_action">
      <img class="logout-action-img" src="/images/logout-action.svg"/>
         Logout
    </a>
  </li>
</v-navigation-drawer>
</template>
<script>
import MaterialIcon from '@/icons/material-icon.vue';

import {
  compSearchEnabled,
  scansEnabled,
  huntsEnabled,
} from '@/utils/features-enabled-utils';

import LogoutMixin from '@/app/screens/mixins/logout-mixin';

import SidebarPageLink from '@/app/components/sidebar-page-link.vue';

export default {
  components: {
    SidebarPageLink,
    MaterialIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    compSearchEnabled,
    scansEnabled,
    huntsEnabled,
  },
  mixins: [LogoutMixin],
  methods: {
    async logoutUser() {
      this.$emit('input', false);
      await this.logout();
    },
  },
};
</script>
<style scoped>
.close-icon-container {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  padding: var(--medium-space);
}
.logout-action-img {
  padding-left: 6px;
  padding-right: 17px;
}
.logout-action {
  font-size: 0.9em;
  font-family: 'Figtree';
  color: #8DABF2;
  font-weight: 900;
}
.logout-action-link {
  margin-top: auto;
  margin-bottom: 100px;
  list-style-type: none;
  margin-left: 23px;
}
</style>
<style>
.toggle-mobile-sidebar-icon .v-icon__svg {
  height: 15px !important;
}
.mobile-sidebar-container .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
}
</style>
