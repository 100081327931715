import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      ignoreRules: null,
    },
    mutations: {
      updateIgnoreRules(state, ignoreRules) {
        state.ignoreRules = ignoreRules;
      },
    },
    actions: {
      refreshIgnoreRules({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/ignore_rules',
        }).then(
          (result) => {
            commit('updateIgnoreRules', result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      // eslint-disable-next-line no-unused-vars
      addIgnoreRule({ dispatch, commit }, params) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: '/api/v1/ignore_rules',
          data: params,
        }).then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            dispatch('refreshIgnoreRules');
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      // eslint-disable-next-line no-unused-vars
      deleteIgnoreRule({ dispatch, commit }, ignoreRuleId) {
        return new Promise((resolve, reject) => {
          this.state.auth.axios_instance({
            method: 'DELETE',
            url: `/api/v1/ignore_rules/${ignoreRuleId}`,
          }).then(
            // eslint-disable-next-line no-unused-vars
            (result) => {
              dispatch('refreshIgnoreRules');
              resolve();
            },
            (error) => {
              promiseErrorHandler(error, reject);
            },
          );
        });
      },
    },
  }
);
