export const translateErrorResponse = (err) => {
  console.log('Server error detected', err);

  let errorText = '';
  if (err.response != null) {
    if (err.response.data != null) {
      if (err.response.data instanceof ArrayBuffer) {
        const enc = new TextDecoder('utf-8');
        const jsonParsedResponse = JSON.parse(enc.decode(err.response.data));
        errorText = jsonParsedResponse.error;
      } else {
        errorText = err.response.data.error;
      }
    }
    if (errorText == null || errorText === '') {
      const errorCode = err.response.status;
      switch (errorCode) {
      case 400:
        errorText = 'Bad request';
        break;
      case 401:
        errorText = 'Authentication error';
        break;
      case 403:
        errorText = 'Forbidden';
        break;
      case 404:
        errorText = 'Not found';
        break;
      case 408:
        errorText = 'Request timeout';
        break;
      case 429:
        errorText = 'Too many requests';
        break;
      case 413:
        errorText = 'File too large, please reduce file size and try again';
        break;
      default:
        errorText = `Error code = ${errorCode}`;
      }
    }
  } else {
    errorText = err.message;
  }
  return errorText;
};
