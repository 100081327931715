export default (
  {
    namespaced: true,
    state: {
      title: '',
      subTitle: '',
    },
    mutations: {
      setTitle(state, { title, subTitle }) {
        state.title = title;
        state.subTitle = subTitle;
      },
    },
  }
);
