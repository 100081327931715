import _ from 'lodash';

export const sendTrackingEvent = (eventName, properties = null) => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        ...properties,
      });
    }
    if (window.mixpanel) {
      window.mixpanel.track(
        eventName,
        {
          ...properties,
        },
      );
    }

    if (window.Intercom) {
      window.Intercom('trackEvent', eventName, properties);
      if (eventName.startsWith('open')) {
        _.debounce(() => {
          window.Intercom('update');
        }, 1000)();
      }
    }
  } catch (err) {
    console.log(`Error on sendTrackingEvent - ${err}`);
  }
};

export const clearTracking = (component) => {
  try {
    if (component.$hj) {
      component.$hj('identify', null, {});
    }

    if (window.Intercom) {
      window.Intercom('shutdown');
    }

    if (window.mixpanel) {
      window.mixpanel.reset();
    }
  } catch (err) {
    console.log(`Error on clearTracking - ${err}`);
  }
};

export const registerTracking = (component, username, signedUserId, email, org, fullName = null) => {
  try {
    console.log(`User for tracking: username: ${username}, signedUserId: ${signedUserId}, email: ${email}, org: ${org}`);

    if (fullName == null || fullName === '') {
      fullName = username;
    }

    if (component.$hj) {
      component.$hj('identify', username, { org, email });
    }
    if (window.$productFruits) {
      window.$productFruits.push(['init', 'WAob5n3KdHitNLtm', 'en', { username, email }]);
    }

    if (window.mixpanel) {
      window.mixpanel.register(
        {
          unique_id: username,
          distinct_id: username,
          username,
          email,
          org,
        },
      );
      window.mixpanel.identify(username);
    }

    if (window.Intercom && signedUserId != null) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'iisr0spy',
        name: fullName, // Full name
        user_id: username, // User ID
        user_hash: signedUserId,
        email, // Email address
        org,
      });
    }
  } catch (err) {
    console.log(`Error on registerTracking - ${err}`);
  }
};
