import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      jobs: [],
    },
    mutations: {
      updateJobs(state, jobs) {
        state.jobs = jobs;
      },
    },
    actions: {
      refreshJobs({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/ondemand_job',
        }).then(
          (result) => {
            commit('updateJobs', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      createJob({ dispatch }, jobDetails) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/v1/ondemand_job',
          data: jobDetails,
        }).then(
          () => {
            dispatch('refreshJobs');
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      abortJob({ dispatch }, jobId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'DELETE',
          url: `/api/v1/ondemand_job/${jobId}`,
        }).then(
          () => {
            dispatch('refreshJobs');
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      updateJob({ dispatch }, jobDetails) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: '/api/v1/ondemand_job',
          data: jobDetails,
        }).then(
          () => {
            dispatch('refreshJobs');
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
