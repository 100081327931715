import axios from 'axios';
import Encryption from '@/mixins/encryption';
import EventBus from '@/assets/eventBus';
import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';
import { getOrgSettingValue, getOrgSettingBooleanValue } from '@/utils/orgSettings';

async function logoutSession(accessToken, refreshToken) {
  try {
    const axiosInstance = axios.create(
      {
        headers: {
          'access-token': accessToken,
          'refresh-token': refreshToken,
        },
      },
    );
    // Add interceptor to bypass cache
    axiosInstance.interceptors.request.use((config) => {
      config.params = config.params || {};
      config.params.timestamp = new Date().getTime();
      return config;
    });
    await axiosInstance(
      {
        method: 'POST',
        url: '/api/v1/logout',
      },
    );
  } catch (err) {
    console.log(`Was unable to logout existing session: ${err}`);
  }
}

export default (
  {
    namespaced: true,
    state: {
      redirectAfterLogin: 'home',
      redirectAfterLogout: null,
      username: '',
      signedUserId: '',
      email: '',
      fullName: '',
      firstName: '',
      lastName: '',
      userAvatar: null,
      access_token: '',
      refresh_token: '',
      org: '',
      encKey: '',
      parent_org: null,
      roles: [],
      authenticated: false,
      org_admin: false,
      site_admin: false,
      users_management: false,
      policy_management: false,
      axios_instance: null,
      remember_me_enabled: false,
      remembered: null,
      licensing: null,
      org_settings: null,
      useOrg: false,
      warnings: null,
    },
    mutations: {
      enableOrgSelection(state) {
        state.useOrg = true;
      },
      updateRedirectAfterLogin(state, newRedirect) {
        if (!newRedirect.includes('login') && !newRedirect.includes('welcome') && !newRedirect.includes('mobile-not-supported')) {
          state.redirectAfterLogin = newRedirect;
        } else {
          state.redirectAfterLogin = 'home';
        }
      },
      updateRedirectAfterLogout(state, newRedirectURL) {
        state.redirectAfterLogout = newRedirectURL;
      },
      saveRememberMe(state, rememberMeParam = null) {
        state.remembered = rememberMeParam;
        if (state.remembered != null) {
          try {
            localStorage.setItem('loginParams', JSON.stringify(state.remembered));
          } catch (err) {
            console.log(`Error saving remember me: ${err}`);
          }
        } else {
          localStorage.setItem('loginParams', null);
        }
        this.commit('auth/readRememberMe');
      },
      clearRememberMe(state) {
        try {
          localStorage.setItem('loginParams', null);
        } catch (err) {
          console.log(`Error clearing remember me: ${err}`);
        }
        state.remembered = null;
        state.remember_me_enabled = false;
      },
      readRememberMe(state) {
        let rememberMeStr = null;
        try {
          rememberMeStr = localStorage.getItem('loginParams');
        } catch (err) {
          console.log(`Error reading remember me: ${err}`);
          rememberMeStr = null;
        }
        const isRemembered = rememberMeStr != null && rememberMeStr !== 'null';
        if (!isRemembered) {
          state.remembered = null;
          return;
        }
        // console.log(`Remembered creds: ${rememberMeStr}`);

        const rememberedParams = JSON.parse(rememberMeStr);
        const { username } = rememberedParams;
        // eslint-disable-next-line camelcase
        const { access_token } = rememberedParams;
        // eslint-disable-next-line camelcase
        const { refresh_token } = rememberedParams;
        const { org } = rememberedParams;
        const { encKey } = rememberedParams;
        state.remembered = {
          username,
          // eslint-disable-next-line camelcase
          access_token,
          // eslint-disable-next-line camelcase
          refresh_token,
          org,
          encKey,
        };
      },
      updateTokens(state, payload) {
        state.access_token = payload.access_token;
        state.refresh_token = payload.refresh_token;

        state.axios_instance = axios.create(
          {
            headers: {
              org: state.org,
              'access-token': state.access_token,
              'refresh-token': state.refresh_token,
            },
          },
        );
        state.axios_instance.interceptors.request.use(
          (config) => {
            const excludedURLs = [
              '/api/v1/file/import_check',
              '/api/v1/repository/find',
            ];
            if (config.method !== 'get' && !excludedURLs.includes(config.url)) {
              this.commit('loader/setLoading', true);
            }

            config.params = config.params || {};
            config.params.timestamp = new Date().getTime();

            return config;
          },
        );
        state.axios_instance.interceptors.response.use(
          (res) => {
            this.commit('loader/setLoading', false);
            const { headers } = res;
            const accessToken = headers.access_token;
            const refreshToken = headers.refresh_token;
            this.commit('auth/updateTokens',
              {
                access_token: accessToken,
                refresh_token: refreshToken,
              },
            );
            return res;
          },
          async (err) => {
            this.commit('loader/setLoading', false);
            if (err.response && err.response.status === 401) {
              EventBus.dispatch('logout');
            } else if (!err.response || !err.response.status
              || (err.response.status !== 400 && err.response.status !== 404)) {
              EventBus.dispatch('communicationIssue', err);
            }
            return Promise.reject(err);
          },
        );
        let rememberMe = null;
        if (state.remember_me_enabled && state.username != null && state.access_token != null) {
          rememberMe = {
            username: state.username,
            access_token: state.access_token,
            refresh_token: state.refresh_token,
            org: state.org,
            encKey: state.encKey,
          };
        }
        this.commit('auth/saveRememberMe', rememberMe);
      },
      setAuthenticated(state, payload) {
        state.username = payload.username;
        state.signedUserId = payload.signedUserId;
        state.email = payload.email;
        state.userAvatar = payload.userAvatar;
        state.firstName = payload.firstName;
        state.lastName = payload.lastName;
        state.fullName = payload.fullName;
        state.access_token = payload.access_token;
        state.refresh_token = payload.refresh_token;
        state.org = payload.org;
        state.encKey = payload.encKey;
        state.parent_org = payload.parent_org;
        state.roles = payload.roles;
        state.licensing = payload.licensing;
        state.org_admin = state.roles.includes('org_admin');
        state.site_admin = state.roles.includes('site_admin');
        state.users_management = state.roles.includes('users_management');
        state.policy_management = state.roles.includes('policy_management');
        state.remember_me_enabled = payload.remember_me_enabled;
        state.org_settings = payload.orgSettings;
        this.commit('auth/updateTokens', payload);
        state.authenticated = true;
        state.warnings = payload.warnings;
      },
      afterLogout(state) {
        state.username = '';
        state.signedUserId = '';
        state.email = '';
        state.fullName = '';
        state.firstName = '';
        state.lastName = '';
        state.userAvatar = null;
        state.access_token = '';
        state.refresh_token = '';
        state.org = '';
        state.parent_org = null;
        state.roles = [];
        state.authenticated = false;
        state.org_admin = false;
        state.site_admin = false;
        state.users_management = false;
        state.policy_management = false;
        state.axios_instance = () => new Promise((res, rej) => {
          rej();
        });
        state.remember_me_enabled = false;
        state.licensing = null;
        state.org_settings = null;
        state.warnings = null;
      },
    },
    actions: {
      async logout({ commit }) {
        try {
          commit('readRememberMe');
        } catch (err) {
          console.log(`Error reading remember me: ${err}`);
        }

        if (this.state.auth.remembered != null) {
          logoutSession(this.state.auth.remembered.access_token, this.state.auth.remembered.refresh_token);
        }

        try {
          commit('clearRememberMe');
        } catch (err) {
          console.log(`Error clearing remember me: ${err}`);
        }

        if (this.state.auth.axios_instance != null) {
          try {
            await this.state.auth.axios_instance(
              {
                method: 'POST',
                url: '/api/v1/logout',
              },
            );
          } catch (err) {
            console.log(`Error perforing logout: ${err}`);
          }
        }
        await commit('afterLogout');
      },
      async validateRememberMe({ commit }) {
        try {
          commit('readRememberMe');

          if (this.state.auth.remembered != null) {
            const axiosInstance = axios.create(
              {
                headers: {
                  org: this.state.auth.org,
                  'access-token': this.state.auth.remembered.access_token,
                  'refresh-token': this.state.auth.remembered.refresh_token,
                },
              },
            );
            // Add interceptor to bypass cache
            axiosInstance.interceptors.request.use((config) => {
              config.params = config.params || {};
              config.params.timestamp = new Date().getTime();
              return config;
            });
            await axiosInstance(
              {
                method: 'GET',
                url: '/api/v1/ping',
              },
            ).then((res) => {
              const { headers } = res;
              const accessToken = headers.access_token;
              const refreshToken = headers.refresh_token;
              const rememberMe = {
                username: this.state.auth.remembered.username,
                access_token: accessToken,
                refresh_token: refreshToken,
                org: this.state.auth.remembered.org,
                encKey: this.state.auth.remembered.encKey,
              };
              commit('saveRememberMe', rememberMe);
            }).catch((err) => {
              console.log(err);
              if (err.response && err.response.status === 401) {
                console.log('remember me token validation failed');
                commit('clearRememberMe');
              } else {
                throw err;
              }
            });
          }
        } catch (err) {
          console.log(`Error validating remember me: ${err}`);
        }
      },
      resetPasswordAck(_, { userName, token, password }) {
        return new Promise((resolve, reject) => axios({
          method: 'POST',
          url: `/api/v1/reset_password_ack/${userName}?timestamp=${new Date().getTime()}`,
          data: {
            token,
            new_password: password,
          },
        }).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          },
        ));
      },
      resetPassword(_, { username }) {
        return new Promise((resolve, reject) => axios({
          method: 'POST',
          url: `/api/v1/reset_password/${username}?timestamp=${new Date().getTime()}`,
        }).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          },
        ));
      },
      login({ commit }, payload) {
        return new Promise((resolve, reject) => {
          let auth = null;
          const reqHeaders = {
            org: payload.org,
          };

          if (payload.provider != null) {
            reqHeaders.Authorization = `Provider ${payload.provider}:${payload.providerToken}`;
          } else if (payload.access_token != null) {
            reqHeaders['access-token'] = payload.access_token;
            reqHeaders['refresh-token'] = payload.refresh_token;
          } else if (payload.password != null) {
            auth = {
              username: payload.username,
              password: payload.password,
            };
          }

          axios({
            method: 'POST',
            url: `/api/v1/login?timestamp=${new Date().getTime()}`,
            auth,
            headers: reqHeaders,
          })
            .then(
              (result) => {
                const { data, headers } = result;
                const accessToken = headers.access_token;
                const refreshToken = headers.refresh_token;
                const userInfo = data.user_info;
                const { warnings } = data;
                let rememberMe = false;

                if (data.org_settings != null) {
                  rememberMe = getOrgSettingBooleanValue(data.org_settings, 'auth', 'remember_me_enabled');
                }

                let errorText = null;
                if (userInfo.role == null || !userInfo.role.includes('site_admin')) {
                  const encryptionChallenge = getOrgSettingValue(data.org_settings, 'global', 'encryption_challenge');
                  const encryptionResponse = getOrgSettingValue(data.org_settings, 'global', 'encryption_response');

                  if (encryptionChallenge != null && encryptionChallenge.length > 0) {
                    if (payload.encKey == null || payload.encKey.length === 0) {
                      errorText = 'Encryption key is required for this organization';
                    } else {
                      const decryptionAttempt = Encryption.methods.decrypt(encryptionResponse, payload.encKey, payload.encKey);
                      if (decryptionAttempt !== encryptionChallenge) {
                        errorText = 'Encryption key is incorrect';
                      }
                    }
                  } else if (payload.encKey != null && payload.encKey.length > 0) {
                    errorText = 'This organization is not using an encryption key';
                  }
                }

                if (errorText != null) {
                  logoutSession(accessToken, refreshToken);
                  reject(Error(errorText));
                  return;
                }

                let org = '';
                let email = '';
                let signedUserId = '';
                let parentOrg = null;
                let roles = [];
                let { username } = payload;
                let fullName = null;

                if (userInfo.org != null) {
                  org = userInfo.org;
                }
                if (userInfo.email != null) {
                  email = userInfo.email;
                }
                if (userInfo.parent_org != null) {
                  parentOrg = userInfo.parent_org;
                }
                if (userInfo.role != null) {
                  roles = userInfo.role;
                }
                if (userInfo.user_id != null) {
                  username = userInfo.user_id;
                } else if (userInfo.preferred_username != null) {
                  username = userInfo.preferred_username;
                }
                if (userInfo.job_title) {
                  this.commit('jobTitle/setJobTitle', userInfo.job_title);
                }

                if (userInfo.signed_user_id != null) {
                  signedUserId = userInfo.signed_user_id;
                }

                let firstName = userInfo.first_name;
                if (firstName == null) {
                  firstName = '';
                }
                let lastName = userInfo.last_name;
                if (lastName == null) {
                  lastName = '';
                }

                fullName = (`${firstName} ${lastName}`).trim();

                if (fullName.length === 0) {
                  fullName = username;
                }

                commit('setAuthenticated',
                  {
                    username,
                    signedUserId,
                    email,
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    org,
                    encKey: payload.encKey,
                    parent_org: parentOrg,
                    roles,
                    licensing: data.licensing,
                    remember_me_enabled: rememberMe,
                    orgSettings: data.org_settings,
                    fullName,
                    firstName,
                    lastName,
                    userAvatar: userInfo.user_avatar,
                    warnings,
                  });
                resolve();
              },
            )
            .catch(
              (error) => {
                promiseErrorHandler(error, reject, () => {
                  if (error.response != null) {
                    const { headers } = error.response;
                    const accessToken = headers.access_token;
                    const refreshToken = headers.refresh_token;
                    if (accessToken != null && refreshToken != null) {
                      logoutSession(accessToken, refreshToken);
                    }
                  }
                });
              },
            );
        });
      },
      // eslint-disable-next-line
      register({ commit }, payload) {
        return new Promise((resolve, reject) => axios({
          method: 'PUT',
          url: `/api/v1/self_register?timestamp=${new Date().getTime()}`,
          data: payload,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            reject(error);
          },
        ));
      },
      getProviderAuthURL(_, provider) {
        return new Promise((resolve, reject) => axios({
          method: 'GET',
          url: `/api/v1/auth_provider/${provider}?timestamp=${new Date().getTime()}`,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            reject(error);
          },
        ));
      },
      validateProviderToken(_, { provider, providerToken }) {
        return new Promise((resolve, reject) => axios({
          method: 'POST',
          url: `/api/v1/auth_provider/${provider}?timestamp=${new Date().getTime()}`,
          data: { providerToken },
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            reject(error);
          },
        ));
      },
    },
  }
);
