import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';
import { ORDER_STATUS } from '@/constants/order-status';

export default (
  {
    namespaced: true,
    state: {
      orders: null,
      selectedOrder: null,
      ordersStatuses: [
        { value: ORDER_STATUS.DRAFT, title: 'Draft' },
        { value: ORDER_STATUS.SUBMITTED, title: 'Quote Req' },
        { value: ORDER_STATUS.QUOTED, title: 'Quoted' },
        { value: ORDER_STATUS.APPROVED_BY_CUSTOMER, title: 'Quote Approved' },
        { value: ORDER_STATUS.APPROVED_BY_VENDOR, title: 'Ordered' },
        { value: ORDER_STATUS.SHIPPED, title: 'Shipped' },
        { value: ORDER_STATUS.DELIVERED, title: 'Delivered' },
        { value: ORDER_STATUS.CANCELED, title: 'Canceled' },
      ],
    },
    mutations: {
      updateOrders(state, payload) {
        state.orders = payload;
      },
      updateSelectedOrder(state, payload) {
        state.selectedOrder = payload;
      },
      clearSelectedOrder(state) {
        state.selectedOrder = null;
      },
    },
    actions: {
      getOrders({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/user_orders',
        }).then(
          (result) => {
            commit('updateOrders', result.data);
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getOrderDetails({ commit }, orderId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/user_orders/${orderId}`,
        }).then(
          (result) => {
            commit('updateSelectedOrder', result.data);
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      createOrder(_, order) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/v1/user_orders',
          data: order,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      uploadFile(_, info) {
        const { orderId, file } = info;
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: `api/v1/files/user_order/${orderId}`,
          data: file,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      updateOrder(_, data) {
        const { orderId, info } = data;
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: `api/v1/user_orders/${orderId}`,
          data: info,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      downloadFile(_, info) {
        const { orderId, fileId } = info;
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `api/v1/files/user_order/${orderId}/${fileId}`,
          responseType: 'blob',
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
