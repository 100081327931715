export const arraysEqual = (a, b) => {
  if (a == null && b == null) return false;
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  a.sort();
  b.sort();

  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const titleParse = (val) => val.replaceAll('_', ' ').replace(
  /\w\S*/g,
  (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);

export const isOverlay = (obj, property) => {
  if (obj) {
    const element = obj[property];
    if (typeof element !== 'object' || !element) {
      return true;
    }

    const arr = Object.keys(element ?? {});
    const isOverlayRes = arr.length === 0;
    return isOverlayRes;
  }
  return true;
};

export const maxStringLength = (text, maxLen) => {
  if (text == null) {
    return text;
  }
  if (text.length > maxLen) {
    return `${text.substr(0, maxLen - 3)}...`;
  }
  return text;
};

export const isArrayHaveElements = (arr) => {
  if (arr) {
    return arr.length !== 0;
  }
  return false;
};

export const sortObjectArrayByProrperty = (arr, property) => arr.sort((a, b) => {
  const distanceA = Object.prototype.hasOwnProperty.call(a, property) ? a[property] : Infinity; // default to Infinity if no distance key
  const distanceB = Object.prototype.hasOwnProperty.call(b, property) ? b[property] : Infinity; // default to Infinity if no distance key

  return distanceA - distanceB;
});

export const isNullValue = (value, nullValue) => {
  if (value != null) {
    return value;
  }
  return nullValue;
};

export const isObject = (val) => typeof val === 'object'
    && !Array.isArray(val)
    && val !== null;

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return `${str.slice(0, maxLength)}...`;
  }
  return str;
};

export const removeNonAlphanumeric = (inputString) => inputString.replace(/[^\w\s\u00C0-\u06FF]/g, '');

export const normalizeSku = (inputSku) => inputSku.replace(/[^a-zA-Z0-9+]/g, '').toLowerCase();

export const escape = (str) => str
  .replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;')
  .replace(/'/g, '&#039;');

export const isChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edg') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');

  if ((isChromium !== null
      && typeof isChromium !== 'undefined'
      && vendorName === 'Google Inc.'
      && isOpera === false
      && isIEedge === false)
      || isIOSChrome
  ) {
    return true;
  }

  return false;
};
