import store from '@/store/store';

export default async ({ next }) => {
  if (store.state.settings.sysSettings == null) {
    await this.$store.dispatch('settings/refreshSysSettings');
  }

  if (store.state.settings.sysSettings == null || store.state.settings.sysSettings.features_self_registration_enabled === '0') {
    return next({ name: 'login' });
  }

  return next();
};
