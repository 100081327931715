<template>
  <nav class="side_nav__cont" id="sidebar">
      <div class="text-center" style="height:57px;">
        <img
          src="images/only_o_logo.svg"
          class="sidebar-img cursor-pointer"
          alt="ikido"
          @click="goToHome()"
          ref="small_logo"/>
        <img
          src="images/sidebar-full-logo.svg"
          class="sidebar-full-img cursor-pointer"
          alt="ikido"
          ref="full_logo"
          @click="goToHome()"/>
      </div>

    <SideBarPageList ref="side_bar_list"/>
    <!--
    <div>
      <PremiumAd v-if="upgradeToPremiumAdEnabled" ref="premium_ad"/>
      <ManageAccount v-if="!upgradeToPremiumAdEnabled" ref="manage_billing"/>
    </div>
    -->
  </nav>
</template>
<script>
import Licensing from '@/mixins/licensing';
// import PremiumAd from '@/app/screens/desktop/components/side-bar-desktop/components/premium-ad.vue';
// import ManageAccount from '@/app/screens/desktop/components/side-bar-desktop/components/manage-account.vue';
import SideBarPageList from '@/app/screens/desktop/components/side-bar-desktop/components/sidebar-page-list.vue';

export default {
  components: {
    // PremiumAd,
    // ManageAccount,
    SideBarPageList,
  },
  mixins: [Licensing],
  computed: {
    upgradeToPremiumAdEnabled() {
      if (!this.isFeatureEnabled('bom_upload_allowed')) {
        return true;
      }
      if (this.licensing.show_upgrade === '1') {
        return true;
      }
      return false;
    },
  },
  methods: {
    goToHome() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>
