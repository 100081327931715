<template>
  <div>
    <v-app-bar
      v-if="showAppBar"
      elevation="4"
      color="#1F3870"
      class="mobile-navbar no-scroll"
      ref="mobile_app_bar">
      <v-app-bar-nav-icon
        color="white"
        @click="$emit('iconClicked')"
        ref="app_bar_icon_action"/>
      <a
        :href="$router.resolve({name: 'home'}).href"
        class="mobile-brand-logo"
        ref="home_redirect_action">
        <img
            src="/images/sidebar-full-logo.svg"
            class="mobile-img-logo cursor-pointer"
            alt="ikido"/>
      </a>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  props: {
    showAppBar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.mobile-navbar {
  z-index: 99;
  position: fixed;
  color: aliceblue;
}
.mobile-brand-logo {
  margin-left: auto;
}
.mobile-img-logo {
  height: 31px;
}

::v-deep .v-icon__svg {
    fill: white !important;
}
</style>
<style>
.mobile-navbar .v-toolbar__content {
  justify-content: flex-start !important;
}
</style>
