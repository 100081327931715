<template>
  <ul class="nav sidebar-nav nav-items">
    <SideBarPageLink
      v-if="homeEnabled"
      id="home_tab"
      route="/home"
      icon="fas fa-home fa-icon"
      isBackgroundHighlight
      title="Home"
      ref="home_page_route"
    />
    <SideBarPageLink
      v-if="compSearchEnabled"
      isBackgroundHighlight
      id="component_browser_tab"
      route="/component-browser"
      icon="/images/component-browser-unselected.svg"
      light-icon="/images/component-browser-selected.svg"
      title="Component Browser"
      :child-route-names="[
        'componentBrowser',
        'componentResults',
        'componentDisplay',
        'parametricSearch',
      ]"
      ref="component_browser_page_route"
    />
    <SideBarPageLink
      :indicate-premium="!scansEnabled"
      isBackgroundHighlight
      id="scans_tab"
      route="/boms"
      icon="fas fa-microchip fa-icon"
      title="Scans"
      :child-route-names="[
        'upgrade-to-boms',
      ]"
      ref="boms_page_route"
    />
    <SideBarPageLink
      :indicate-premium="!huntsEnabled"
      isBackgroundHighlight
      id="hunter_tab"
      route="/hunter"
      icon="fas fa-crosshairs fa-icon"
      title="Hunter"
      :child-route-names="[
        'upgrade-to-hunter',
      ]"
      ref="hunts_page_route"
    />
    <SideBarPageLink
      v-if="isOrderSupportEnabled && !isMultiOrg"
      isBackgroundHighlight
      id="orders_tab"
      route="/orders"
      icon="/images/orders-unselected.svg"
      lightIcon="/images/orders-selected.svg"
      title="Orders"
      :child-route-names="[
        'orders',
        'orderDetails',
      ]"
      ref="orders_page_route"
    />
    <SideBarPageLink
      v-if="ignoreRulesEnabled"
      isBackgroundHighlight
      id="ignore_rules_tab"
      route="/ignoreRules"
      icon="fas fa-shield-halved fa-icon"
      title="Ignore Rules"
      ref="ignore_rules_page_route"
    />
    <SideBarPageLink
      v-if="org !== '*'"
      :indicate-premium="!productsInsightsEnabled"
      isBackgroundHighlight
      id="products_tab"
      route="/products"
      icon="fas fa-chart-line fa-icon"
      title="Products"
      :child-route-names="[
        'upgrade-to-product',
      ]"
      ref="products_page_route"
    />
    <SideBarPageLink
      isBackgroundHighlight
      v-if="adminEnabled"
      id="admin_tab"
      ref="admin_route_link">
      <span
        slot="content"
        @click="goToAdmin()"
        class="route-path"
        :class="isInAdmin?'active':''"
        ref="admin_route_span">
        <div class="route-link-wrap">
          <i class="fas fa-cog fa-icon"></i>
           <span>Admin</span>
        </div>
      </span>
    </SideBarPageLink>
  </ul>
</template>
<script>
import SideBarPageLink from '@/app/components/sidebar-page-link.vue';
import {
  homeEnabled,
  compSearchEnabled,
  scansEnabled,
  huntsEnabled,
  ignoreRulesEnabled,
  productsInsightsEnabled,
  adminEnabled,
  isOrderSupportEnabled,
} from '@/utils/features-enabled-utils';

import {
  org,
  isOrgAdmin,
  isUsersManagement,
  isPolicyManagement, isMultiOrg,
} from '@/utils/auth-utils';

export default {
  components: {
    SideBarPageLink,
  },
  methods: {
    goToAdmin() {
      if (this.isOrgAdmin && !this.isMultiOrg) {
        this.$router.push({ name: 'general_settings' });
      } else if (this.isUsersManagement) {
        this.$router.push({ name: 'users' });
      } else if (this.isPolicyManagement && !this.isMultiOrg) {
        this.$router.push({ name: 'riskPolicy' });
      } else {
        alert('Not authorized');
      }
    },
  },
  computed: {
    isOrderSupportEnabled,
    isInAdmin() {
      return this.$route.path.includes('/admin/');
    },
    homeEnabled,
    compSearchEnabled,
    scansEnabled,
    huntsEnabled,
    ignoreRulesEnabled,
    productsInsightsEnabled,
    adminEnabled,
    isOrgAdmin,
    isUsersManagement,
    isPolicyManagement,
    org,
    isMultiOrg,
  },
};
</script>
