export default {
  methods: {
    async logout() {
      this.$store.commit('auth/updateRedirectAfterLogin', 'home');
      try {
        await this.$store.dispatch('auth/logout');
      } catch (err) {
        console.log(`Error performing logout: ${err}`);
      }
      if (
        this.$store.state.auth.redirectAfterLogout != null
        && this.$store.state.auth.redirectAfterLogout.length > 0
      ) {
        const redirectURL = this.$store.state.auth.redirectAfterLogout;
        await this.$store.reset();
        window.document.body.style.display = 'none';
        window.location.href = redirectURL;
      } else {
        await this.$store.reset();
        await this.$router.push({ name: 'login' });
      }
    },
  },
};
