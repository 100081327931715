/* eslint-disable no-unused-vars */
import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      workspaces: null,
      selectedWorkspace: null,
      insightsHistory: null,
      alternatives: null,
    },
    mutations: {
      updateWorkspaces(state, workspaces) {
        state.workspaces = workspaces;
      },
      updateSelectedWorkspace(state, selectedWorkspace) {
        state.selectedWorkspace = selectedWorkspace;
      },
      updateInsightsHistory(state, insightsHistory) {
        state.insightsHistory = insightsHistory;
      },
      updateAlternatives(state, alternatives) {
        state.alternatives = alternatives;
      },
      clearSelectedWorkspace(state) {
        state.selectedWorkspace = null;
        state.insightsHistory = null;
        state.alternatives = null;
      },
      clearInsightsHistory(state) {
        state.insightsHistory = null;
      },
      clearAlternatives(state) {
        state.alternatives = null;
      },
      clearChangeNotifications(state) {
        state.changeNotifications = null;
      },
    },
    actions: {
      refreshWorkspaces({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/workspace',
        }).then(
          (result) => {
            commit('updateWorkspaces', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      selectAndGetWorkspaceDetails({ commit }, workspaceId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/workspace/summary/${workspaceId}`,
        }).then(
          (result) => {
            commit('updateSelectedWorkspace', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getInsightsHistory({ commit }, workspaceId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/workspace/insights_history/${workspaceId}`,
        }).then(
          (result) => {
            commit('updateInsightsHistory', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getAlternatives({ commit }, { workspaceId, itemSku }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: itemSku ? `/api/v1/workspace/alternatives_new/${workspaceId}/${itemSku}` : `/api/v1/workspace/alternatives_new/${workspaceId}`,
        }).then(
          (result) => {
            commit('updateAlternatives', result.data);
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getChangeNotifications({ commit }, { workspaceId, itemSku }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/workspace/change_notifications/${workspaceId}/${itemSku}`,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      deleteWorkspace({ dispatch }, workspaceId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'DELETE',
          url: `/api/v1/workspace/${workspaceId}`,
        }).then(
          () => {
            dispatch('refreshWorkspaces');
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      assignToProduct(_, { workspaceId, params }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: `/api/v1/workspace/product_assignment/${workspaceId}`,
          data: params,
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      unAssignFromProduct(_, workspaceId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'DELETE',
          url: `/api/v1/workspace/product_assignment/${workspaceId}`,
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      updateSubscribedState(_, { workspaceId, state }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: state ? 'PUT' : 'DELETE',
          url: `/api/v1/workspace/subscribe/${workspaceId}`,
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      runAgain({ dispatch }, workspaceId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: `/api/v1/workspace/run_again/${workspaceId}`,
        }).then(
          () => {
            dispatch('refreshWorkspaces');
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      runAlternativesSearch(_, { workspaceId, minRisk, items }) {
        if (minRisk !== undefined && minRisk !== null) {
          return new Promise((resolve, reject) => this.state.auth.axios_instance({
            method: 'POST',
            url: `/api/v1/workspace/rerun_alternatives/${workspaceId}/${minRisk}`,
          }).then(
            () => {
              resolve();
            },
            (error) => {
              promiseErrorHandler(error, reject);
            },
          ));
        }
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: `/api/v1/workspace/alternatives_new/${workspaceId}`,
          data: items,
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      runOptimizationAgain(_, { workspaceId, optimizationSettings }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: `/api/v1/workspace/rerun_optimization/${workspaceId}`,
          data: optimizationSettings,
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      updateMonitoringState({ dispatch, commit }, { workspaceId, monitoringState }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: monitoringState ? 'PUT' : 'DELETE',
          url: `/api/v1/workspace/monitor/${workspaceId}`,
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getWorkspaceAllOptions({ dispatch, commit }, workspaceId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/workspace/all_purchase_options/${workspaceId}`,
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getWorkspaceSource({ dispatch, commit }, workspaceId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/workspace/source/${workspaceId}`,
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getSupplyDetails({ dispatch, commit }, { workspaceId, orderId, index }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/workspace/supply/${workspaceId}/${orderId}/${index}`,
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      rename(_, { workspaceId, newLabel }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: `/api/v1/workspace/label/${workspaceId}`,
          data: {
            new_label: newLabel,
          },
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getWorkspaceVersions({ dispatch, commit }, workspaceId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/workspace/versions/${workspaceId}`,
        }).then(
          (res) => {
            resolve(res.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      compareWorkspaces({ dispatch, commit }, { firstWorkspaceId, secondWorkspaceId }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/workspace/compare/${firstWorkspaceId}/${secondWorkspaceId}`,
        }).then(
          (res) => {
            resolve(res.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      updateBusinesStatus({ dispatch, commit }, { workspaceId, businessStatus }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: `/api/v1/workspace/business_status/${workspaceId}`,
          data: {
            new_status: businessStatus,
          },
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      updateOwner({ dispatch, commit }, { workspaceId, owner }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: `/api/v1/workspace/owner/${workspaceId}`,
          data: {
            new_owner_id: owner,
          },
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
