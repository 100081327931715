export class ORDER_STATUS {
  static DRAFT = 'draft';

  static CANCELED = 'canceled';

  static SUBMITTED = 'submitted';

  static QUOTED = 'quoted';

  static APPROVED_BY_CUSTOMER = 'approved_by_customer';

  static APPROVED_BY_VENDOR = 'approved_by_vendor';

  static SHIPPED = 'shipped';

  static DELIVERED = 'delivered';
}
