import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      techAtts: null,
    },
    mutations: {
      updateTechAtts(state, techAtts) {
        state.techAtts = techAtts;
      },
    },
    actions: {
      refreshTechAtts({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/tech_atts',
        }).then(
          (result) => {
            commit('updateTechAtts', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
