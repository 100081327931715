/* eslint-disable no-unused-vars */
import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      pinnedItems: null,
      categories: null,
    },
    mutations: {
      updatePinnedItems(state, pinnedItems) {
        state.pinnedItems = pinnedItems;
      },
      updateCategories(state, categories) {
        state.categories = categories;
      },
    },
    actions: {
      searchPurchaseOptions({ dispatch, commit }, query) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/v1/component/supply_options',
          data: query,
        }).then(
          (result) => {
            const { data } = result;
            resolve(data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      searchComponentsByKeyword({ dispatch, commit }, keyword) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/v1/component/search',
          data: {
            keyword,
          },
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getRecentSearches({ dispatch, commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/component/recent',
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          }));
      },
      autoCompleteKeyword({ dispatch, commit }, partialKeyword) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/repository/find/${encodeURIComponent(partialKeyword)}`,
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getPinnedItems({ dispatch, commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/pinned_items',
        }).then(
          (result) => {
            commit('updatePinnedItems', result.data);
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          }));
      },
      deletePinnedItem({ dispatch, commit }, parameters) {
        const { manufacturerSku } = parameters;
        const encodedManufacturerSku = encodeURIComponent(manufacturerSku);
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'DELETE',
          url: `/api/v1/pinned_items/${encodedManufacturerSku}`,
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          }));
      },
      addPinnedItem({ dispatch, commit }, parameters) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/v1/pinned_items',
          data: parameters,
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      findItemsWithParameters(_, { params, controller }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/v1/repository/find',
          data: params,
          signal: controller.signal,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getCategoriesAndParameters({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/component/parametric_search_def',
        }).then(
          (result) => {
            commit('updateCategories', result.data);
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
