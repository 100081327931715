<template>
  <li
    :id="id"
    class="side_nav__items"
    @mouseover="mouseOver = true"
    @mouseleave="mouseOver = false"
    @focusin="mouseOver = true"
    @focusout="mouseOver = false"
    ref="li">
    <slot name="content">
      <router-link
        :to="route"
        :active-class="activeClass"
        class="route-path"
        ref="router_link">
        <div class="route-link-wrap">
          <i
            v-if="!lightIcon"
            :class="icon"
            ref="icon_display"></i>
          <slot name="icon">
            <img
              v-if="lightIcon"
              class="side-bar-image-icon"
              :src="iconSource"
              ref="img_icon_display"/>
          </slot>
          <span class="link-title">{{title}}</span>
          <img
            v-if="indicatePremium"
            class="premium-page-indicator"
            src="/images/premium-page-diamond.svg"
            ref="premium_indicator"/>
        </div>
      </router-link>
    </slot>
  </li>
</template>
<script>

export default {
  props: {
    route: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    lightIcon: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    childRouteNames: {
      type: Array,
      default: () => [],
    },
    indicatePremium: {
      type: Boolean,
      default: false,
    },
    isBackgroundHighlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mouseOver: false,
    };
  },
  computed: {
    iconSource() {
      if (this.childRouteNames.find((name) => name === this.$route.name) || this.mouseOver) {
        return this.lightIcon;
      }
      return this.icon;
    },
    activateNonImageIcon() {
      if (this.childRouteNames.find((name) => name === this.$route.name) || this.mouseOver) {
        return true;
      }
      return false;
    },
    activeClass() {
      if (this.isBackgroundHighlight) {
        return 'active-with-background';
      }
      return 'active';
    },
  },
};
</script>
