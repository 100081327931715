import store from '@/store/store';
import { parametricSearchEnabled } from '@/utils/features-enabled-utils';

export default ({ next, to }) => {
  if (!parametricSearchEnabled()) {
    return next({ name: 'notfound' });
  }
  if (store.state.components.categories && !Object.keys(store.state.components.categories).find((category) => category === to.params.category)) {
    return next({ name: 'notfound' });
  }
  return next();
};
