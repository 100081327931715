import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vuetify from 'vuetify/lib';
import { CUSTOM_COLORS } from '@/constants/colorsConstants';

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['~/assets/variables.scss'],
  icons: {
    iconfont: 'mdiSvg', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: CUSTOM_COLORS.CALL_TO_ACTION_BLUE,
        secondary: '#424242',
        blue: '#2D51A3',
      },
    },
  },
});
