import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default ({
  namespaced: true,
  actions: {
    addComment(_, {
      contextId, newComment, contextType, contextOwner, contextLabel,
    }) {
      return new Promise((resolve, reject) => this.state.auth.axios_instance({
        method: 'POST',
        url: `/api/v1/comments/${contextType}/${contextId}`,
        data: {
          comment_text: newComment,
          context_owner_id: contextOwner,
          context_label: contextLabel,
        },
      }).then(
        (res) => {
          resolve(res.data);
        },
        (error) => {
          promiseErrorHandler(error, reject);
        },
      ));
    },
    getComments(_, { contextId, contextType }) {
      return new Promise((resolve, reject) => this.state.auth.axios_instance({
        method: 'GET',
        url: `/api/v1/comments/${contextType}/${contextId}`,
      }).then(
        (res) => {
          resolve(res.data);
        },
        (error) => {
          promiseErrorHandler(error, reject);
        },
      ));
    },
  },
});
