import isFeatureEnabled from '@/utils/licensingUtils';
import { getOrgSettingBooleanValue } from '@/utils/orgSettings';
import store from '@/store/store';

export const compSearchEnabled = () => store.state.auth.org !== '*'
&& getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'comp_search_enabled')
&& isFeatureEnabled(store.state.auth.licensing, store.state.auth.site_admin, 'comp_browser_allowed');

export const scansEnabled = () => store.state.auth.org === '*'
|| isFeatureEnabled(store.state.auth.licensing, store.state.auth.site_admin, 'bom_upload_allowed');

export const huntsEnabled = () => store.state.auth.org === '*'
|| isFeatureEnabled(store.state.auth.licensing, store.state.auth.site_admin, 'hunter_allowed');

export const homeEnabled = () => store.state.auth.org !== '*'
&& isFeatureEnabled(store.state.auth.licensing, store.state.auth.site_admin, 'bom_upload_allowed');

export const ignoreRulesEnabled = () => store.state.auth.org !== '*'
&& isFeatureEnabled(store.state.auth.licensing, store.state.auth.site_admin, 'ignore_rules_allowed');

export const optimizeFeatureEnabled = () => store.state.auth.org !== '*'
&& isFeatureEnabled(store.state.auth.licensing, store.state.auth.site_admin, 'bom_optimization_wizard_allowed');

export const productsInsightsEnabled = () => store.state.auth.org !== '*' && isFeatureEnabled(store.state.auth.licensing, store.state.auth.site_admin, 'product_dashboards_allowed');

export const pinItemsEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'pinned_items_enabled');

export const adminEnabled = () => (store.state.auth.org_admin || store.state.auth.users_management || store.state.auth.policy_management);

export const isFreeUser = () => !(scansEnabled() || huntsEnabled() || productsInsightsEnabled());

export const isWelcomeStageEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'welcome_stage_enabled');

export const isBomCommentsEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'bom_comments_enabled');

export const isBomStatusEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'business_status_enabled');

export const isNewScansEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'new_scan_page');

export const isPcnEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'pcn_enabled');

export const edaEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'eda_enabled');

export const isInUseEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'in_use_enabled');

export const loginWithProviderEnabled = () => (Boolean(store.state.settings.sysSettings) && store.state.settings.sysSettings.auth_with_providers_enabled === '1');

export const parametricSearchEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'parametric_search_enabled');

export const isActivityLogEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'activity_log_enabled');

export const isOrderSupportEnabled = () => getOrgSettingBooleanValue(store.state.auth.org_settings, 'features', 'order_support_enabled');
