<template>
  <div>
    <router-view v-if="!isNavBarRequired" ref="no_transition_view" />
    <div v-else :class="authenticatedUsersPageClass">
      <vue-page-transition name="fade">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
      </vue-page-transition>
      <vue-page-transition name="fade">
        <router-view v-if="!$route.meta.keepAlive" :key="$route.path" />
      </vue-page-transition>
    </div>
  </div>
</template>
<script>
import DeviceCheckerMixin from '@/mixins/device-checker-mixin';

export default {
  mixins: [
    DeviceCheckerMixin,
  ],
  props: {
    isNavBarRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    authenticatedUsersPageClass() {
      let screenClass = 'main-content-desktop';
      if (this.isMobile) {
        screenClass = 'main-content-mobile';
      }
      return `${screenClass} global-padding`;
    },
  },
};
</script>
<style scoped>

.main-content-desktop {
  margin-left:60px;
  margin-top: 54px;
  padding: var(--standard-space);
  position: relative;
  height: calc(100vh - 54px);
}

.main-content-mobile {
  padding: var(--standard-space);
  height: calc(100vh - 54px);
}

</style>
