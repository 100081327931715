export const getOrgSettingValue = (orgSettings, settingSection, settingName) => {
  if (!orgSettings) {
    return null;
  }

  let setting;

  if (Array.isArray(orgSettings)) {
    setting = orgSettings.find(
      (orgSetting) => orgSetting.section === settingSection
        && orgSetting.name === settingName);
  }

  if (!setting) {
    return null;
  }

  if (setting.value !== null && setting.value !== '') {
    return setting.value;
  }

  return setting.default_value;
};

export const getOrgSettingBooleanValue = (orgSettings, settingSection, settingName) => {
  const value = getOrgSettingValue(orgSettings, settingSection, settingName);
  if (!value) {
    return false;
  }
  return String(value) === '1' || String(value).toLowerCase() === 'true';
};
