/* eslint-disable no-unused-vars */
import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      hunts: null,
    },
    mutations: {
      updateHunts(state, hunts) {
        state.hunts = hunts;
      },
    },
    actions: {
      refreshHunts({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/hunter',
        }).then(
          (result) => {
            commit('updateHunts', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      addHunt({ state, commit }, { parameters }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/v1/hunter/add',
          data: parameters,
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
