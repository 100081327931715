import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default ({
  namespaced: true,
  state: {
    bomStatuses: null,
  },
  mutations: {
    setBomStatuses(state, statuses) {
      state.bomStatuses = statuses;
    },
  },
  actions: {
    updateBomStatus({ commit }, bomStatuses) {
      return new Promise((resolve, reject) => this.state.auth.axios_instance({
        method: 'PUT',
        url: '/api/v1/business_status',
        data: bomStatuses,
      }).then(
        (res) => {
          commit('setBomStatuses', res.data);
          resolve(res.data);
        },
        (error) => {
          promiseErrorHandler(error, reject);
        },
      ));
    },
    getBomStatuses({ commit }) {
      return new Promise((resolve, reject) => this.state.auth.axios_instance({
        method: 'GET',
        url: '/api/v1/business_status',
      }).then(
        (res) => {
          commit('setBomStatuses', res.data);
          resolve(res.data);
        },
        (error) => {
          promiseErrorHandler(error, reject);
        },
      ));
    },
  },
});
