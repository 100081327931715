import isFeatureEnabled from '@/utils/licensingUtils';

export default {
  computed: {
    isSiteAdmin() {
      return this.$store.state.auth.site_admin;
    },
    licensing() {
      return this.$store.state.auth.licensing;
    },
  },
  methods: {
    isFeatureEnabled(featureKey) {
      return isFeatureEnabled(this.licensing, this.isSiteAdmin, featureKey);
    },
  },
};
