/* eslint-disable */
import licenseBasedRoute from '@/utils/routeUtils';
import {
  isMobile,
} from '@/utils/deviceCheckerUtils';

export default ({ next }) => {
  if (isMobile()) {
    return next({ name: 'componentBrowser' });
  }

  return licenseBasedRoute(next, 'bom_upload_allowed', false, 'componentBrowser');
};
