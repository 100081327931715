import store from '@/store/store';

export default ({ next, to }) => {
  if (!store.state.auth.authenticated) {
    if (to.meta.requiresAuth !== false) {
      store.commit('auth/updateRedirectAfterLogin', to.fullPath);
    }
    return next({ name: 'login' });
  }

  return next();
};
