<template>
  <div
    class="avatar"
    :class="smallAvatar ? 'small-avatar' : 'big-avatar'"
    :style="{ 'background-color': userAvatar.color }"
    data-cy="user-avatar"
    :data-username="userAvatar.tooltip"
  >
    <CustomTooltip :disabled="smallAvatar ? true : false" :text="userAvatar.tooltip" ref="user-tooltip">
      <div class="avatar-letters" :class="smallAvatar ? 'small-letters text-3' : 'big-letters heading-2'">
        {{ userAvatar.letters }}
      </div>
    </CustomTooltip>
  </div>
</template>

<script>
import CustomTooltip from '@/components/ui-elements/custom-tooltip.vue';

export default {
  components: {
    CustomTooltip,
  },
  props: {
    userAvatar: {
      type: Object,
      default: () => {},
    },
    smallAvatar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.avatar {
  display: flex;
  align-items: center;
  border-radius: 50%;
}
.big-avatar {
  width: 40px;
  height: 40px;
}
.small-avatar {
  width: 20px;
  height: 20px;
}
.avatar-letters {
  color: white;
  text-align: center;
}
.big-letters {
  width: 40px;
  height: 25.5px;
}
.small-letters {
  width: 20px;
}
</style>
