export default {
  data() {
    return {
      err: '',
      snackbarShow: false,
    };
  },
  methods: {
    raiseNotification(err) {
      this.$notify.error({
        title: 'Error',
        message: err,
      });
    },
    goTo404Page() {
      this.$router.replace({ name: 'notfound' });
    },
  },
};
