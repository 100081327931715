<template>
  <v-app :dark="true" id="app" class="app">
    <Loader
      v-if="$store.state.loader.loading"
      ref="loader"/>
    <v-idle
      @idle="logoutOnTimeout()"
      :duration="idleTimeoutDuration"
      style="display:none;"
      ref="idle"/>
    <DesktopPage v-if="!isMobile" ref="desktop_template"/>
    <MobilePage v-else ref="mobile_template"/>
  </v-app>

</template>

<script>
import axios from 'axios';
import EventBus from '@/assets/eventBus';
import errorHandling from '@/mixins/errorHandling';
import { translateErrorResponse } from '@/utils/server-comm-utils';
import DeviceCheckerMixin from '@/mixins/device-checker-mixin';
import Loader from './components/loader.vue';
import DesktopPage from './screens/desktop/desktop-page.vue';
import MobilePage from './screens/mobile/mobile-page.vue';

export default {
  components: {
    Loader,
    DesktopPage,
    MobilePage,
  },
  mixins: [DeviceCheckerMixin, errorHandling],
  name: 'iKido',
  data() {
    return {
      versionChecker: null,
    };
  },
  computed: {
    idleTimeoutDuration() {
      if (this.org === '*') {
        return 300;
      }
      return 3600;
    },
    org() {
      return this.$store.state.auth.org;
    },
  },
  mounted() {
    this.onActivated();
  },
  beforeDestroy() {
    this.onDeactivated();
  },
  activated() {
    this.onActivated();
  },
  deactivated() {
    this.onDeactivated();
  },
  methods: {
    translateErrorResponse,
    logoutOnTimeout() {
      this.$store.commit('auth/afterLogout');
      this.$store.commit('auth/updateRedirectAfterLogin', this.$route.path);
      this.$router.push({ name: 'login', params: { timeout: true } });
    },
    onActivated() {
      EventBus.on('logout', () => {
        this.$store.dispatch('auth/logout');
        this.logoutOnTimeout();
      });
      EventBus.on('communicationIssue', (err) => {
        if (!axios.isCancel(err)) {
          this.raiseNotification(translateErrorResponse(err));
        }
      });

      this.$store.dispatch('version/getVersion');
      this.versionChecker = setInterval(() => {
        this.$store.dispatch('version/getVersion');
      }, 1000 * 60); // every 1 minute

      if (this.$store.state.settings.sysSettings == null) {
        this.$store.dispatch('settings/refreshSysSettings');
      }
    },
    onDeactivated() {
      EventBus.remove('logout');
      clearInterval(this.versionChecker);
    },
  },
};
</script>

<style>
body {
  font-family: Figtree, "Gill Sans", "Calibri", sans-serif;
  font-size: 0.9rem !important;
  overflow: hidden;
}
.app {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  min-width: 100%;
  min-height: 100vh;
  background: white;
}

.center-text {
  text-align: center;
}

.huge_icon {
  height: 120px;
  width: 120px;
}

.xlarge_icon {
  height: 60px;
  width: 60px;
}

.big_icon {
  height: 40px;
  width: 40px;
}

.bigmedium_icon {
  height: 28px;
  width: 28px;
}

.medium_icon {
  height: 25px;
  width: 25px;
}

.smallmedium_icon {
  height: 20px;
  width: 20px;
}

.small_icon {
  height: 15px;
  width: 15px;
}

.action-link {
  float: right;
  color: var(--call-to-action-color);
  margin-right: 10px;
  font-size: 12px;
}
.link {
  color: var(--call-to-action-color);
  cursor: pointer;
}

.nav-link {
  color: #62ace5 !important;
}

.nav-link:hover {
  color: var(--call-to-action-color) !important;
}

.nav-link.active {
  color: var(--call-to-action-color) !important;
}

.nav-link.disabled {
  color: lightgray !important;
}

.link:hover {
  text-decoration: underline;
}
.animated {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
.animated_slow {
  -webkit-animation: fa-spin 3s infinite steps(100);
  animation: fa-spin 3s infinite steps(100);
}

.vt-sort:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  padding-right: 0.5em;
  width: 1.28571429em;
  display: inline-block;
  text-align: center;
}

.vt-sortable:before {
  content: "\f338";
}

.vt-asc:before {
  content: "\f161";
}

.vt-desc:before {
  content: "\f160";
}

.btn-link {
  cursor: pointer;
  color: var(--call-to-action-color) !important;
}

  .form-control{
    border-radius: var(--standard-border-radius-large) !important;
  }

  .custom-select{
    border-radius: var(--standard-border-radius-large) !important;
  }

  .btn{
    cursor: pointer;
    font-family: Figtree, "Gill Sans", "Calibri", sans-serif;
    font-weight: 500 !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: var(--standard-border-radius-large) !important;
    font-size: 0.8rem !important;
  }

.btn_small {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-weight: bold !important;
  border-radius: 10px !important;
}

.el-select__tags-text {
  color: black !important;
}

.el-tag.el-tag--info {
  background-color: #ffffff !important;
}

.el-select-dropdown__item.selected {
  color: black !important;
}

div.modal .modal-dialog.modal-xxl {
  max-width: calc(100vw - 100px);
}

.default_link {
  color: var(--call-to-action-color) !important;
}

.default_link:hover {
  color: #3969d8 !important;
}

.subtitle {
  color: var(--rich-black);
}
.v-table,
thead,
tr > th {
  background-color: #ffffff;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 3;
}
.sticky-column {
  background-color: #ffffff;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  left: 0;
  z-index: 2;
  background-clip: padding-box;
}

.sticky-header {
  background-color: #ffffff;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;
}

.user_icon {
  height: 25px;
  width: 30px;
}

.checkbox_text {
  margin-left: 10px;
  color: var(--call-to-action-color);
}

.filter-btn-hover:hover {
  background-color: #cad3e5;
}

.spaced {
  margin-left: 5px;
  margin-right: 5px;
}

#info_tab {
  margin-left: 9px;
}
.v-toolbar__content {
  justify-content: center;
}

</style>
