import { maxStringLength } from '@/utils/generalUtils';

export default {
  computed: {
    pageTitle() {
      return this.$store.state.title.title;
    },
    pageSubTitleDate() {
      return this.$store.state.title.subTitle;
    },
    pageTitleComputed() {
      return maxStringLength(this.pageTitle, 50);
    },
  },
};
