import CryptoJS from 'crypto-js';

export default {
  computed: {
    key() {
      return this.$store.state.auth.encKey;
    },
    iv() {
      return this.$store.state.auth.org;
    },
  },
  methods: {
    hashCode(str) {
      return CryptoJS.SHA256(str);
    },
    makeKey(key) {
      if (key == null || key === '') {
        return key;
      }
      const hash = String(this.hashCode(key));
      let result = hash.repeat((32 / hash.length) + 1);
      result = result.substring(0, 32);
      return result;
    },
    encrypt(txt, paramKey = null, paramIv = null) {
      let keyToUse = paramKey;
      if (keyToUse == null) {
        keyToUse = this.key;
      }
      if (keyToUse == null || keyToUse === '') {
        return txt;
      }
      keyToUse = CryptoJS.enc.Utf8.parse(this.makeKey(keyToUse));

      let ivToUse = paramIv;
      if (ivToUse == null) {
        ivToUse = this.iv;
      }

      if (txt == null || txt === '') {
        return txt;
      }

      if (txt.startsWith('ENC_')) {
        return txt;
      }

      const cipher = CryptoJS.AES.encrypt(txt, keyToUse, {
        mode: CryptoJS.mode.ECB,
        iv: ivToUse,
      });

      return `ENC_${cipher.toString()}`;
    },
    decrypt(txt, paramKey = null, paramIv = null) {
      let keyToUse = paramKey;
      if (keyToUse == null) {
        keyToUse = this.key;
      }
      keyToUse = CryptoJS.enc.Utf8.parse(this.makeKey(keyToUse));

      if (keyToUse == null || keyToUse === '') {
        return txt;
      }

      let ivToUse = paramIv;
      if (ivToUse == null) {
        ivToUse = this.iv;
      }

      if (txt == null || txt === '') {
        return txt;
      }

      if (!txt.startsWith('ENC_')) {
        return txt;
      }

      try {
        const cipherText = txt.substring('ENC_'.length);

        const cipher = CryptoJS.AES.decrypt(cipherText, keyToUse, {
          mode: CryptoJS.mode.ECB,
          iv: ivToUse,
        });

        const result = CryptoJS.enc.Utf8.stringify(cipher).toString();
        if (result == null || result === '') {
          console.log('Error in decryption: result is empty');
          return txt;
        }
        return result;
      } catch (err) {
        console.log(`Error in decryption: ${JSON.stringify(err)}`);
        return txt;
      }
    },
  },
};
