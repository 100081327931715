<template>
  <span>
    <v-tooltip bottom v-if="tooltip">
      <template v-slot:activator="{ on, attrs }">
          <v-icon
            :size="size"
            :class="ccsClass"
            @click="$emit('onClick');"
            :color="color"
            :id="id"
            :style="isClickable ? '' : 'cursor:unset;'"
            :disabled="false"
            v-bind="attrs"
            v-on="on"
          >
              <slot></slot>
          </v-icon>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <v-icon
        v-else
        :size="size"
        :class="ccsClass"
        @click="$emit('onClick');"
        :color="color"
        :id="id"
        :style="isClickable ? '' : 'cursor:unset;'"
        :disabled="isClickable ? false : true"
      >
        <slot></slot>
    </v-icon>
   </span>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '',
    },
    ccsClass: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>

<style>

</style>
