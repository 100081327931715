import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    actions: {
      makeApiRequest(_, payload) {
        const {
          url, formData, username, password, org,
        } = payload;

        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          data: formData,
          url,
          auth: {
            username,
            password,
          },
          headers: {
            org,
          },
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
