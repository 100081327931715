<template>
<div class="desktop-template relative">
  <SideBarDesktop
    v-if="isNavBarRequired"
    ref="side_bar_desktop"
  />
  <AppNavBar
    v-if="isNavBarRequired"
    ref="app_nav_bar"
  />
  <PageRouterView
    :isNavBarRequired="isNavBarRequired"
    ref="page_view"
  />
</div>
</template>
<script>
import AppNavBar from '@/app/screens/desktop/components/app-navbar.vue';
import SideBarDesktop from '@/app/screens/desktop/components/side-bar-desktop/side-bar-desktop.vue';
import PageRouterView from '@/app/components/page-router-view.vue';
import NavBarRequiredPageMixin from '@/app/screens/mixins/nav-bar-required-mixin';

export default {
  components: {
    SideBarDesktop,
    AppNavBar,
    PageRouterView,
  },
  mixins: [NavBarRequiredPageMixin],
  name: 'iKido-default-template',
  mounted() {
    this.$store.commit('title/setTitle', { title: 'Home' });
  },
};
</script>
<style scoped>
.desktop-template {
  height: 100vh;
}

.desktop-app-container {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.container-guest {
  top: 0px;
}

.container-auth {
  top: 54px;
}
</style>
<style>
.username-display {
  color: var(--glaucous-blue) !important;
  font-size: 14px;
  margin-left: 6px;
}
</style>
