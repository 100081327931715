export default function isFeatureEnabled(licensing, isSiteAdmin, featureKey) {
  if (isSiteAdmin) {
    return true;
  }
  if (licensing == null) {
    return true;
  }

  const featureAllowedStr = licensing[featureKey];
  if (featureAllowedStr == null || featureAllowedStr === '') {
    return true;
  }

  return featureAllowedStr === '1' || featureAllowedStr === 'true';
}
