export default (
  {
    namespaced: true,
    state: {
      jobTitle: null,
    },
    mutations: {
      setJobTitle(state, jobTitle) {
        state.jobTitle = jobTitle;
      },
    },
    actions: {
      async updateJobTitle(_, jobTitle) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/v1/user/job_title',
          data: {
            job_title: jobTitle,
          },
        }).then(
          (result) => {
            this.commit('jobTitle/setJobTitle', jobTitle);
            resolve(result.data);
          },
          (error) => {
            reject(error);
          },
        ));
      },
      async getJobTitles() {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/user/job_titles',
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            reject(error);
          },
        ));
      },
    },
  }
);
