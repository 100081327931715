/* eslint-disable */
import axios from 'axios';

export default (
  {
    namespaced: true,
    state: {
      version: null,
    },
    mutations: {
      updateVersion(state, version) {
        if (state.version !== null && state.version !== version) {
          location.reload();
        } else {
          state.version = version;
        }
      },
    },
    actions: {
      getVersion({ commit }) {
        axios.get(`/version.txt?_t=${Date.now()}`, {
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
            'Content-Type': 'application/json',
            Accept: 'application/json' 
          }
        }).then(
          (result) => {
            commit('updateVersion', result.data);
          },
          (error) => {
            console.error(error);
          },
        );
      },
    },
  }
);
