/* eslint-disable */
import licenseBasedRoute from '@/utils/routeUtils';
import {
  isMobile,
} from '@/utils/deviceCheckerUtils';

export default ({ next }) => {
  if (isMobile()) {
    return next({ name: 'componentBrowser' });
  }

  return licenseBasedRoute(next, 'product_dashboards_allowed', false, 'upgrade-to-product');
};
