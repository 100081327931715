import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      orders: null,
      selectedOrder: null,
    },
    mutations: {
      updateOrders(state, payload) {
        state.orders = payload;
      },
      updateSelectedOrder(state, payload) {
        state.selectedOrder = payload;
      },
      clearSelectedOrder(state) {
        state.selectedOrder = null;
      },
    },
    actions: {
      getOrders({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/v1/vendor_orders',
        }).then(
          (result) => {
            commit('updateOrders', result.data);
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getOrderDetails({ commit }, orderId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/vendor_orders/${orderId}`,
        }).then(
          (result) => {
            commit('updateSelectedOrder', result.data);
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      responseToVendor(_, data) {
        const { orderId, info } = data;
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: `api/v1/vendor_orders/${orderId}`,
          data: info,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      updateVendorOrder(_, data) {
        const { orderId, info } = data;
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: `api/v1/vendor_orders/${orderId}`,
          data: info,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      uploadVendorFiles(_, info) {
        const { orderId, file } = info;
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: `api/v1/vendor_orders/${orderId}/files`,
          data: file,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      exportOrderFiles(_, orderId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `api/v1/vendor_orders/${orderId}/export`,
          responseType: 'blob',
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      downloadFile(_, info) {
        const { orderId, fileId } = info;
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/v1/vendor_orders/${orderId}/files/${fileId}`,
          responseType: 'blob',
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getActivityLog(_, { contextId, contextType }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `api/v1/${contextType}/${contextId}/activity_log`,
        }).then(
          (res) => {
            resolve(res.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      getComments(_, { contextId, contextType }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `api/v1/${contextType}/${contextId}/comments`,
        }).then(
          (res) => {
            resolve(res.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      addComment(_, {
        contextId, newComment, contextType, contextOwner, contextLabel,
      }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: `api/v1/${contextType}/${contextId}/comments`,
          data: {
            comment_text: newComment,
            context_owner_id: contextOwner,
            context_label: contextLabel,
          },
        }).then(
          (res) => {
            resolve(res.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
