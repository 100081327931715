<template>
<div class="mobile-template" :style="{height: windowHeight}">
  <div class="mobile-header">
    <MobileAppBar
      :showAppBar="isNavBarRequired"
      @iconClicked="showSideBar = !showSideBar"
      ref="mobile_app_bar"/>
  </div>
  <div class="mobile-page-view"
    :class="{
      'guest-page-view': !isNavBarRequired,
      'auth-page-view': isNavBarRequired,
    }">
    <PageRouterView
      :isNavBarRequired="isNavBarRequired"
      ref="page_view"
    />
  </div>
  <MobileSidebar
    v-if="isNavBarRequired"
    v-model="showSideBar"
    class="mobile-sidebar"
    ref="mobile_side_bar"/>
</div>
</template>
<script>
import PageRouterView from '@/app/components/page-router-view.vue';
import IsNavBarRequiredMixin from '@/app/screens/mixins/nav-bar-required-mixin';
import MobileSidebar from './components/mobile-sidebar.vue';
import MobileAppBar from './components/mobile-app-bar.vue';

export default {
  data() {
    return {
      showSideBar: false,
    };
  },
  components: {
    MobileSidebar,
    PageRouterView,
    MobileAppBar,
  },
  mixins: [IsNavBarRequiredMixin],
  computed: {
    windowHeight() {
      return `${window.innerHeight}px`;
    },
  },
};
</script>
<style scoped>
.mobile-sidebar {
  position: fixed;
  z-index: 100 !important;
}

.mobile-template {
  position: relative;
}

.guest-page-view {
  top: 0px;
  bottom: 0px;
  position: absolute;
  width: 100%;
}
.auth-page-view {
  top: 0px;
  bottom: 0px;
  position: absolute;
  width: 100%;
}

.mobile-sidebar ::v-deep ul li a {
  padding-left: 15px !important;
}
.mobile-sidebar ::v-deep ul {
  padding-left: 0px;
}
::v-deep .v-overlay__scrim{
  position: fixed !important;
}

</style>
