import Vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';
import auth from './modules/auth';
import workspaces from './modules/workspaces';
import hunts from './modules/hunts';
import products from './modules/products';
import integrations from './modules/integrations';
import settings from './modules/settings';
import licensing from './modules/licensing';
import loader from './modules/loader';
import ignoreRules from './modules/ignoreRules';
import title from './modules/title';
import users from './modules/users';
import fieldsMappings from './modules/fieldsMappings';
import version from './modules/version';
import techAtts from './modules/techAtts';
import components from './modules/components';
import riskPolicy from './modules/riskPolicy';
import bomOptimization from './modules/bomOptimization';
import fetchErrorBom from './modules/fetchErrorBom';
import eda from './modules/eda';
import votes from './modules/votes';
import jobTitle from './modules/jobTitle';
import comments from './modules/comments';
import bomStatuses from './modules/bomStatuses';
import inUse from './modules/inUse';
import activityLog from './modules/activityLog';
import mfrInfo from './modules/mfrInfo';
import translators from './modules/translators';
import userOrder from './modules/userOrder';
import vendorOrder from './modules/vendorOrder';
import scanUpload from './modules/scanUpload';
import jobs from './modules/jobs';

Vue.use(Vuex);

export default createStore(Vuex.Store,
  {
    modules: {
      auth,
      workspaces,
      hunts,
      products,
      integrations,
      settings,
      licensing,
      loader,
      ignoreRules,
      title,
      users,
      fieldsMappings,
      version,
      techAtts,
      components,
      riskPolicy,
      bomOptimization,
      fetchErrorBom,
      eda,
      votes,
      jobTitle,
      comments,
      bomStatuses,
      inUse,
      activityLog,
      mfrInfo,
      translators,
      userOrder,
      vendorOrder,
      scanUpload,
      jobs,
    },
  },
);
