import LoadScript from 'vue-plugin-load-script';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import SmartTable from 'vuejs-smart-table';
import Vue from 'vue';
import VuePapaParse from 'vue-papa-parse';
import locale from 'element-ui/lib/locale/lang/en';
import ElementUI from 'element-ui';
import Vidle from 'v-idle';
import PrimeVue from 'primevue/config';
import VuePageTransition from 'vue-page-transition';
import VueApexCharts from 'vue-apexcharts';
import App from './app/App.vue';
import router from './router';
import store from './store/store';
import 'bootstrap/dist/css/bootstrap.css';
import 'element-ui/lib/theme-chalk/index.css';
import initProgress from './progressbar';
import vuetify from './plugins/vuetify';
import './styles/app.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'floating-vue/dist/style.css';

Vue.prototype.$bus = new Vue();
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.config.productionTip = false;

console.log(`Mode==${process.env.NODE_ENV}`);
Vue.use(LoadScript);
Vue.use(SmartTable);
Vue.use(PrimeVue);
Vue.use(VuePapaParse);
Vue.use(ElementUI, { locale });
Vue.use(Vidle);
Vue.use(VuePageTransition);

if (process.env.NODE_ENV === 'production') {
  // Vue.loadScript('js/gtm.js');
  // Vue.use(Hotjar, {
  //   id: '3072513',
  // });
  // Vue.loadScript('js/userguiding.js');
  // Vue.loadScript('js/productfruits.js');
  // Vue.loadScript('https://js.hs-scripts.com/6897711.js');
  Vue.loadScript('js/intercom.js');
  Vue.loadScript('js/mixpanel.js');
  Vue.use(VueReCaptcha,
    {
      siteKey: '6Lc3xmQhAAAAAMLB1OJDdjAyj3YPiBa3So4LDFLi',
      loaderOptions: {
        autoHideBadge: true,
      },
    });
}

initProgress(router);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
