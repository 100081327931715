<template>
   <CustomIcon
   :id="id"
   :size="size"
   :ccsClass="ccsClass"
   @onClick="onClick()"
   :color="color"
   :isClickable="isClickable"
   :tooltip="tooltip"
   >
        {{ setIcon }}
    </CustomIcon>
</template>

<script>
import {
  mdiAlertOutline,
  mdiCheckboxBlankOutline,
  mdiChevronDown,
  mdiChevronRight,
  mdiCheckboxMarkedOutline,
  mdiCheckCircleOutline,
  mdiCheck,
  mdiViewDashboardVariant,
  mdiDatabaseSettingsOutline,
  mdiTrayArrowDown,
  mdiCogOutline,
  mdiInformationOutline,
  mdiPlusBoxOutline,
  mdiDotsVertical,
  mdiClose,
  mdiChevronUp,
  mdiPlus,
  mdiTrayArrowUp,
  mdiMenuDown,
  mdiPencilOutline,
} from '@mdi/js';
import CustomIcon from '@/components/ui-elements/custom-icon.vue';

export default {
  components: {
    CustomIcon,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    ccsClass: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    setIcon() {
      switch (this.type) {
      case 'alert':
        return mdiAlertOutline;
      case 'box-blank':
        return mdiCheckboxBlankOutline;
      case 'box-marked':
        return mdiCheckboxMarkedOutline;
      case 'check-circle':
        return mdiCheckCircleOutline;
      case 'check':
        return mdiCheck;
      case 'dashboard':
        return mdiViewDashboardVariant;
      case 'data':
        return mdiDatabaseSettingsOutline;
      case 'download':
        return mdiTrayArrowDown;
      case 'gear':
        return mdiCogOutline;
      case 'info':
        return mdiInformationOutline;
      case 'plus-box':
        return mdiPlusBoxOutline;
      case 'right-arrow':
        return mdiChevronRight;
      case 'down-arrow':
        return mdiChevronDown;
      case 'up-arrow':
        return mdiChevronUp;
      case 'dots-vertical':
        return mdiDotsVertical;
      case 'close':
        return mdiClose;
      case 'plus':
        return mdiPlus;
      case 'upload':
        return mdiTrayArrowUp;
      case 'menu-down':
        return mdiMenuDown;
      case 'edit':
        return mdiPencilOutline;
      default:
        return '';
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style>

</style>
