/* eslint-disable */
import store from '@/store/store';
import isFeatureEnabled from '@/utils/licensingUtils';

export default function licenseBasedRoute(next, licenseKey, redirectOnLicensed, redirectName){
  if (store.state.auth.authenticated){
    const is_licensed = isFeatureEnabled(store.state.auth.licensing, store.state.auth.site_admin, licenseKey)
    if (is_licensed === redirectOnLicensed) {
      return next({ name: redirectName });
    }
  }

  return next();
};
