<template>
  <v-app-bar
      :color="appBarColor"
      class="header"
      elevation="0"
      height="54px"
      ref="app_bar">
    <div class="flex justify-center w-full">
      <v-toolbar-title
          class="pageTitle heading-2"
          id="page_title"
          ref="page_title_computed">{{ pageTitleComputed }}
      </v-toolbar-title>
      <v-toolbar-title
          class="pageSubTitle heading-4"
          ref="page_subtitle">{{ pageSubTitleDate }}
      </v-toolbar-title>
    </div>
    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="menu-activator-container">
          <v-btn
              class="menu-activator"
              id="logged_as"
              v-bind="attrs"
              v-on="on"
              type="button"
              elevation="0"
              :color="appBarColor"
              :title="org"
              light
          >
            <UserAvatar :userAvatar="myAvatar" :smallAvatar="true" />
            <span class="username-display">
              {{ fullName }}
              <img src="/images/user-down-icon.svg"/>
            </span>
          </v-btn>
        </div>
      </template>
      <v-list>
        <v-list-item @click="goToTeam()" id="team" ref="team_action" v-if="isUserManagementAllowed">
          <v-list-item-title>Team</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout()" id="logout" ref="logout_action">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import LogoutMixin from '@/app/screens/mixins/logout-mixin';
import TitleMixin from '@/app/screens/mixins/title-mixin';

import {
  myAvatar, org, fullName, isUsersManagement,
} from '@/utils/auth-utils';
import UserAvatar from '@/components/shared/users/user-avatar.vue';
import Licensing from '@/mixins/licensing';

export default {
  components: { UserAvatar },
  mixins: [LogoutMixin, TitleMixin, Licensing],
  computed: {
    appBarColor() {
      if (!this.$route.meta || !this.$route.meta.appBarColor) {
        return 'white';
      }
      return this.$route.meta.appBarColor;
    },
    org,
    myAvatar,
    fullName,
    isUserManagementAllowed() {
      return this.isFeatureEnabled('user_management_allowed') && isUsersManagement();
    },
  },
  methods: {
    goToTeam() {
      this.$router.push({ name: 'users' });
    },
  },
};
</script>

<style scoped>
.menu-activator-container {
  margin-left: auto;
  width: 0px;
  display: flex;
  justify-content: flex-end;
}

.menu-activator {
  margin-right: 15px;
}

.header {
  height: 54px;
  padding-left: 60px;
  z-index: 100;
  width: 100%;
  position: fixed;
  top: 0;
}

.v-application > .v-menu__content {
  margin-top: 32px;
  margin-left: 0px;
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 0px !important;
}

.page-title {
  color: var(--rich-black);
}

.pageSubTitle {
  font-style: normal;
  padding-top: 5px;
  margin-left: var(--standard-space);
  color: var(--oregon-blue);
}
</style>
