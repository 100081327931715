<template>
  <v-tooltip
    bottom
    :disabled="!text || disabled"
    :color="tooltipBackgroundColor"
    ref="tooltip">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="w-100"
        :class="contentClass"
        :style="contentStyle">
        <slot></slot>
      </div>
    </template>
    <div>
      <slot name="tooltip" :text="text">
        {{ text }}
      </slot>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentStyle: {
      type: Object | String,
      default: () => {},
    },
    tooltipBackgroundColor: {
      type: String,
      default: '',
    },
  },
};
</script>
